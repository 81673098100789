.p-overflow-hidden {
    overflow: hidden;
    padding-right: var(--scrollbar-width);
}

@layer primeng {
    .p-component,
    .p-component * {
        box-sizing: border-box;
    }

    .p-hidden {
        display: none;
    }

    .p-hidden-space {
        visibility: hidden;
    }

    .p-hidden-accessible {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .p-hidden-accessible input,
    .p-hidden-accessible select {
        transform: scale(0);
    }

    .p-reset {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        text-decoration: none;
        font-size: 100%;
        list-style: none;
    }

    .p-disabled,
    .p-disabled * {
        cursor: default !important;
        pointer-events: none;
    }

    .p-component-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .p-unselectable-text {
        user-select: none;
    }

    .p-scrollbar-measure {
        width: 100px;
        height: 100px;
        overflow: scroll;
        position: absolute;
        top: -9999px;
    }

    @-webkit-keyframes p-fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes p-fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    input[type='button'],
    input[type='submit'],
    input[type='reset'],
    input[type='file']::-webkit-file-upload-button,
    button {
        border-radius: 0;
    }

    .p-link {
        text-align: left;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        user-select: none;
    }

    .p-link:disabled {
        cursor: default;
    }

    .p-sr-only {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }

    /* Non ng overlay animations */
    .p-connected-overlay {
        opacity: 0;
        transform: scaleY(0.8);
        transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
    }

    .p-connected-overlay-visible {
        opacity: 1;
        transform: scaleY(1);
    }

    .p-connected-overlay-hidden {
        opacity: 0;
        transform: scaleY(1);
        transition: opacity 0.1s linear;
    }

    .p-toggleable-content.ng-animating {
        overflow: hidden;
    }

    .p-icon-wrapper {
        display: inline-flex;
    }

    .p-icon {
        display: inline-block;
    }

    .p-icon-spin {
        -webkit-animation: p-icon-spin 2s infinite linear;
        animation: p-icon-spin 2s infinite linear;
    }
}

@-webkit-keyframes p-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes p-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@layer primeng {
    .p-badge {
        display: inline-block;
        border-radius: 10px;
        text-align: center;
        padding: 0 0.5rem;
    }

    .p-overlay-badge {
        position: relative;
    }

    .p-overlay-badge .p-badge {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        transform-origin: 100% 0;
        margin: 0;
    }

    .p-badge-dot {
        width: 0.5rem;
        min-width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        padding: 0;
    }

    .p-badge-no-gutter {
        padding: 0;
        border-radius: 50%;
    }
}

@layer primeng {
    .p-button {
        margin: 0;
        display: inline-flex;
        cursor: pointer;
        user-select: none;
        align-items: center;
        vertical-align: bottom;
        text-align: center;
        overflow: hidden;
        position: relative;
    }

    .p-button-label {
        flex: 1 1 auto;
    }

    .p-button-icon-right {
        order: 1;
    }

    .p-button:disabled {
        cursor: default;
        pointer-events: none;
    }

    .p-button-icon-only {
        justify-content: center;
    }

    .p-button-icon-only:after {
        content: 'p';
        visibility: hidden;
        clip: rect(0 0 0 0);
        width: 0;
    }

    .p-button-vertical {
        flex-direction: column;
    }

    .p-button-icon-bottom {
        order: 2;
    }

    .p-buttonset .p-button {
        margin: 0;
    }

    .p-buttonset .p-button:not(:last-child) {
        border-right: 0 none;
    }

    .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 0;
    }

    .p-buttonset .p-button:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-buttonset .p-button:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .p-buttonset .p-button:focus {
        position: relative;
        z-index: 1;
    }

    p-button[iconpos='right'] spinnericon {
        order: 1;
    }
}

@layer primeng {
    .p-checkbox {
        display: inline-flex;
        cursor: pointer;
        user-select: none;
        vertical-align: bottom;
        position: relative;
    }

    .p-checkbox-disabled {
        cursor: default !important;
        pointer-events: none;
    }

    .p-checkbox-box {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p-checkbox {
        display: inline-flex;
        vertical-align: bottom;
        align-items: center;
    }

    .p-checkbox-label {
        line-height: 1;
    }
}

.p-colorpicker-panel .p-colorpicker-color {
    background: transparent url("./images/color.png") no-repeat left top; 
}

.p-colorpicker-panel .p-colorpicker-hue {
   background: transparent url("./images/hue.png") no-repeat left top; 
}
@layer primeng {
    .p-inputtext {
        margin: 0;
    }

    .p-fluid .p-inputtext {
        width: 100%;
    }

    /* InputGroup */
    .p-inputgroup {
        display: flex;
        align-items: stretch;
        width: 100%;
    }

    .p-inputgroup-addon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-inputgroup .p-float-label {
        display: flex;
        align-items: stretch;
        width: 100%;
    }

    .p-inputgroup .p-inputtext,
    .p-fluid .p-inputgroup .p-inputtext,
    .p-inputgroup .p-inputwrapper,
    .p-inputgroup .p-inputwrapper > .p-component {
        flex: 1 1 auto;
        width: 1%;
    }

    /* Floating Label */
    .p-float-label {
        display: block;
        position: relative;
    }

    .p-float-label label {
        position: absolute;
        pointer-events: none;
        top: 50%;
        margin-top: -0.5rem;
        transition-property: all;
        transition-timing-function: ease;
        line-height: 1;
    }

    .p-float-label textarea ~ label {
        top: 1rem;
    }

    .p-float-label input:focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label textarea:focus ~ label,
    .p-float-label textarea.p-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
        top: -0.75rem;
        font-size: 12px;
    }

    .p-float-label .input:-webkit-autofill ~ label {
        top: -20px;
        font-size: 12px;
    }

    .p-float-label .p-placeholder,
    .p-float-label input::placeholder,
    .p-float-label .p-inputtext::placeholder {
        opacity: 0;
        transition-property: all;
        transition-timing-function: ease;
    }
    .p-float-label .p-focus .p-placeholder,
    .p-float-label input:focus::placeholder,
    .p-float-label .p-inputtext:focus::placeholder {
        opacity: 1;
        transition-property: all;
        transition-timing-function: ease;
    }

    .p-input-icon-left,
    .p-input-icon-right {
        position: relative;
        display: inline-block;
    }

    .p-input-icon-left > i,
    .p-input-icon-left > .p-icon-wrapper,
    .p-input-icon-right > i,
    .p-input-icon-right > .p-icon-wrapper {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }

    .p-fluid .p-input-icon-left,
    .p-fluid .p-input-icon-right {
        display: block;
        width: 100%;
    }
}

@layer primeng {
    .p-inputtextarea-resizable {
        overflow: hidden;
        resize: none;
    }

    .p-fluid .p-inputtextarea {
        width: 100%;
    }
}

@layer primeng {
    .p-password {
        position: relative;
        display: inline-flex;
    }

    .p-password-panel {
        position: absolute;
        top: 0;
        left: 0;
    }

    .p-password .p-password-panel {
        min-width: 100%;
    }

    .p-password-meter {
        height: 10px;
    }

    .p-password-strength {
        height: 100%;
        width: 0%;
        transition: width 1s ease-in-out;
    }

    .p-fluid .p-password {
        display: flex;
    }

    .p-password-input::-ms-reveal,
    .p-password-input::-ms-clear {
        display: none;
    }

    .p-password-clear-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }

    .p-password-clearable.p-password-mask .p-password-clear-icon {
        margin-top: unset;
    }

    .p-password-clearable {
        position: relative;
    }
}

@layer primeng {
    .p-radiobutton {
        display: inline-flex;
        cursor: pointer;
        user-select: none;
        vertical-align: bottom;
        position: relative;
    }

    .p-radiobutton-box {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-radiobutton-icon {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: translateZ(0) scale(0.1);
        border-radius: 50%;
        visibility: hidden;
    }

    .p-radiobutton-box.p-highlight .p-radiobutton-icon {
        transform: translateZ(0) scale(1, 1);
        visibility: visible;
    }

    p-radiobutton {
        display: inline-flex;
        vertical-align: bottom;
        align-items: center;
    }

    .p-radiobutton-label {
        line-height: 1;
    }
}

@layer primeng {
    .p-ripple {
        overflow: hidden;
        position: relative;
    }

    .p-ink {
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        transform: scale(0);
    }

    .p-ink-active {
        animation: ripple 0.4s linear;
    }

    .p-ripple-disabled .p-ink {
        display: none !important;
    }
}

@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}

@layer primeng {
    .p-tooltip {
        position: absolute;
        display: none;
        padding: 0.25em 0.5rem;
        max-width: 12.5rem;
        pointer-events: none;
    }

    .p-tooltip.p-tooltip-right,
    .p-tooltip.p-tooltip-left {
        padding: 0 0.25rem;
    }

    .p-tooltip.p-tooltip-top,
    .p-tooltip.p-tooltip-bottom {
        padding: 0.25em 0;
    }

    .p-tooltip .p-tooltip-text {
        white-space: pre-line;
        word-break: break-word;
    }

    .p-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .p-tooltip-right .p-tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -0.25rem;
        border-width: 0.25em 0.25em 0.25em 0;
    }

    .p-tooltip-left .p-tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -0.25rem;
        border-width: 0.25em 0 0.25em 0.25rem;
    }

    .p-tooltip.p-tooltip-top {
        padding: 0.25em 0;
    }

    .p-tooltip-top .p-tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -0.25rem;
        border-width: 0.25em 0.25em 0;
    }

    .p-tooltip-bottom .p-tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -0.25rem;
        border-width: 0 0.25em 0.25rem;
    }
}
